import React, { useEffect, useContext } from "react"

import { categories } from "../../static/assets/js/categories"
import LayoutContext from "../components/layout/layout.context"
import HistoriesContext from "../context/histories.context"
import useHistoriesData from "../hooks/useHistoriesData"
import Home from "../components/template/home/Home"
import CategoryHeader from "../components/modules/categoryHeader/CategoryHeader"

const OffRoad = () => {
  const context = useContext(LayoutContext)
  const historiesData = useHistoriesData()
  const { offroad } = categories

  useEffect(() => {
    context.setActiveCategory(3)
    return () => {
      context.setActiveCategory(undefined)
    }
  }, [])

  return (
    <HistoriesContext.Provider value={historiesData}>
      <div className="wrapper">
        <CategoryHeader title={offroad.title} desc={offroad.desc} />
        <Home category="off-road" hideHeader />
      </div>
    </HistoriesContext.Provider>
  )
}

export default OffRoad
